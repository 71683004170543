import { XButtonRender } from "../../../../modules/note/mobile/components/XButtonRender";
import { collSideBar } from "../../../../modules/note/pc/components/collSideBar";
import { KEYS_CONST_NAME } from "../../../../modules/note/pc/consts";
import { OrderedListOutlined } from "@ant-design/icons";
import type { Note } from "@chengde/schemas/lib/db-entries/index";
import { useDocumentVisibility, useLocalStorageState } from "ahooks";
import { Button, Space } from "antd";
import { default as classNames } from "classnames";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Menu from "../Menu";
import { searchKeyWordQueryConstantName, useNoteContext } from "../NoteContext";
import { nameScrollableDiv } from "./hooks/useContainerAdapt";

import style from "./style.module.css";
import { useUserAgent } from "@/utils/userAgent";

// eslint-disable-next-line complexity
const List = ({
	height,
	onItemClick,
	hasTagMenu = true,
	hasModuleMenu = true,
}: {
	height?: number;
	onItemClick?: (item: Note) => void;
	hasTagMenu?: boolean;
	hasModuleMenu?: boolean;
}) => {
	const context = useNoteContext();
	const { isMobile, isPC } = useUserAgent();
	const { formHook, listHook, id, action, cursor } = context;
	const sideBarStateHook = useLocalStorageState<boolean>("sideBarHook", {
		defaultValue: !!isMobile,
	});
	const [searchParams, setSearchParams] = useSearchParams();

	const listTotal = listHook?.data?.total ?? 0;
	const dataSet = listHook?.data?.data ?? [];
	const hasRemind = formHook.watch("hasRemind");
	const keyValue = formHook.watch("key");
	const documentVisibility = useDocumentVisibility();

	useEffect(() => {
		if (documentVisibility === "visible") {
			formHook.setFocus(KEYS_CONST_NAME);
		}
	}, [documentVisibility, formHook]);

	if (sideBarStateHook[0]) {
		return collSideBar(sideBarStateHook[1], sideBarStateHook[0]);
	}

	return (
		<div
			className="w-full md:w-1/5 "
			style={{
				borderRight: "1px solid #eceff4",
			}}
		>
			<div ref={(ref) => {}} className="max-h-screen flex flex-col shrink-0">
				<div className="flex flex-row space-x-4 items-center px-3">
					<Menu sideBarHook={sideBarStateHook} formHook={formHook} />
				</div>

				<div className="flex flex-row items-center space-x-1 px-3">
					<input
						className="border-gray-200 border w-max flex-1"
						id="searchInput"
						autoComplete="off"
						onKeyUp={(e) => {
							if (e.key === "Enter") {
								setSearchParams((s) => {
									s.set(searchKeyWordQueryConstantName, keyValue ?? "");

									return s;
								});
								listHook.refetch();
							}
						}}
						{...formHook.register(KEYS_CONST_NAME)}
					/>
					{(formHook?.getValues(KEYS_CONST_NAME)?.length ?? 0) > 0 && (
						<XButtonRender
							onMouseUp={() => {
								formHook.setValue(KEYS_CONST_NAME, "");
							}}
						/>
					)}
					<div className="cursor-pointer">
						<OrderedListOutlined
							style={hasRemind ? { fontSize: "16px", color: "#08c" } : {}}
							onClick={() => {
								formHook.setValue("hasRemind", !hasRemind);
							}}
						/>
					</div>
					<div>{listTotal}</div>
				</div>
				{/* {hasTagMenu && (
        <Controller
          control={formHook.control}
          name="tagId"
          render={({ field }) => (
            // @ts-expect-error type-mismatch
            <TagsSelect {...field} hasEditMode={true}></TagsSelect>
          )}
        />
      )} */}
				<div
					id={nameScrollableDiv}
					className={`${style.scrollableDiv} overflow-auto flex-1 px-3`}
				>
					{dataSet?.map((item, index: number) => {
						return (
							<div
								key={item.uuid}
								onMouseUp={() => {
									onItemClick?.(item);

									if (isMobile) {
										sideBarStateHook[1]((a) => !a);
									}
									// router.push(catNote(action, item.noteId));
								}}
								className={classNames("my-3 p-1", {
									"bg-gray-100": id === item.uuid,
								})}
							>
								<div
									className={classNames(
										"cursor-pointer text truncate text-base",
									)}
									style={{
										color: "#232d47",
									}}
								>
									{item.isBlog && "[博客]"} {item.title}
								</div>
								<div
									className={`${style.contentBlock} text-slate-500 cursor-pointer text  text-sm text-ellipsis overflow-hidden`}
								>
									{item.content}
								</div>
							</div>
						);
					})}
				</div>
				<div className="flex flex-row gap-2 m-3">
					<Button
						disabled={(formHook?.watch("page") ?? 0) - 1 < 0}
						onClick={() => {
							const page = formHook?.getValues("page") ?? 0;

							formHook.setValue("page", page - 1);
							listHook.refetch();
						}}
					>
						Previous
					</Button>
					<Button
						onClick={() => {
							const page = formHook?.getValues("page") ?? 0;

							formHook.setValue("page", page + 1);
							listHook.refetch();
						}}
					>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
};

export default List;
