import { uploadFile } from '../../../utils/qiniu';
import { TrpcBase } from '../../../utils/trpc';
import type { PayloadUpdateNoteRemindModel } from '@chengde/dotnet-api/lib/api/models/PayloadUpdateNoteRemindModel';
import { useLocalStorageState } from 'ahooks';
import type { UploadProps } from 'antd';
import { Button, Checkbox, DatePicker, Form, message } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useEffect } from 'react';
import { useNoteContext } from './NoteContext';
import { notChecked } from './Write';

export const WriteModeMenu = ({
	uploadedCallback,
}: {
	uploadedCallback?: (res: any) => any;
}) => {
	const { id: uuid, noteDetail } = useNoteContext();
	const [form] = Form.useForm();
	const addToBlog = TrpcBase.note.addToBlog.useMutation();
	const removeFromBlog = TrpcBase.note.removeFromBlog.useMutation();
	const updateRemind = TrpcBase.note.updateRemind.useMutation();
	const insertRemind = TrpcBase.note.insertRemind.useMutation();
	const remindDetail = TrpcBase.note.remindOf.useQuery(
		{ uuid: uuid },
		{
			enabled: uuid !== '' && !isNil(uuid),
		},
	);

	const [remindToggle, setRemindToggle] = useLocalStorageState<boolean>(
		'remindToggle',
		{
			defaultValue: true,
		},
	);

	useEffect(() => {
		if (remindDetail?.data) {
			form.setFieldsValue(remindDetail.data);
		}
	}, [remindDetail.data, form]);

	const props: UploadProps = {
		name: 'file',
		multiple: false,
		itemRender: function emptyRender() {
			return null;
		},
		async customRequest(options) {
			const { file, onSuccess, onProgress } = options;

			const res = await uploadFile(file as File);

			if (res) {
				uploadedCallback?.(res);
			}
		},
	};

	const formDiv = (
		<Form<PayloadUpdateNoteRemindModel>
			form={form}
			onFinish={async (values) => {
				try {
					if (remindDetail.data) {
						await updateRemind.mutateAsync({
							isChecked: Boolean(values.isChecked),
							remindAt: values.remindAt as unknown as number,
							id: remindDetail.data.id,
							noteUuid: uuid,
						});
					} else {
						await insertRemind.mutateAsync({
							isChecked: Boolean(values.isChecked),
							remindAt: values.remindAt as unknown as number,
							noteUuid: uuid,
						});
					}
				} catch (error: any) {
					message.error(error.message);
				}

				message.success('提醒设置成功');

				remindDetail.refetch();
			}}
		>
			<Form.Item
				name="isChecked"
				label="是否完成"
				valuePropName="checked"
				initialValue={notChecked}
				getValueFromEvent={(e) => {
					return e.target.checked ? 1 : 0;
				}}
				getValueProps={(value) => {
					return {
						checked: value === 1,
					};
				}}
			>
				<Checkbox />
			</Form.Item>
			<Form.Item
				name="remindAt"
				label="提醒"
				getValueFromEvent={(e) => {
					if (e) {
						return e.unix() * 1000;
					}

					return undefined;
				}}
				getValueProps={(value) => {
					if (value) {
						return {
							value: dayjs(value),
						};
					}

					return { value: undefined };
				}}
			>
				<DatePicker bordered={false} showTime />
			</Form.Item>

			<Button
				onClick={() => {
					form.submit();
				}}
			>
				submit
			</Button>
		</Form>
	);

	return (
		<>
			{!isNil(uuid) && (
				<Button
					onClick={() => {
						setRemindToggle((a) => !a);
					}}
				>
					remindAt
				</Button>
			)}

			{remindToggle && formDiv}

			{/* <Upload {...props}>
        <button>文件上传</button>
      </Upload> */}
			{/*
            <Controller
              render={({ field }) => <TypeChoice {...field}></TypeChoice>}
              control={control}
              name="type"
              defaultValue={10}
            /> */}

			<Button
				onClick={async () => {
					try {
						const res = await addToBlog.mutateAsync({
							noteUuid: uuid,
							tagId: 3,
						});
					} catch (e) {
						message.error(`添加失败 ${e.message}`);
					}
				}}
			>
				add to blog
			</Button>
			<Button
				onClick={async () => {
					const res = await removeFromBlog.mutateAsync({
						noteUuid: uuid,
						tagId: 3,
					});
				}}
			>
				remove from blog
			</Button>
		</>
	);
};
