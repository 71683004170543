import type { Note } from '@chengde/dotnet-api/lib/api/';
import type { PayloadUpdateNoteModel } from '@chengde/dotnet-api/lib/api/models/PayloadUpdateNoteModel';
import { message } from 'antd';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { initEntity } from '../../../../modules/note/pc/consts';
import { TrpcBase } from '../../../../utils/trpc';
import { useUserAgent } from '../../../../utils/userAgent';
import { noteIdQueryConstantName, useNoteContext } from '../NoteContext';
import { whenCatchError } from './whenCatchError';
import { isNil } from 'lodash-es';

export function useNoteCommon({ onSave }: { onSave: (note: Note) => void }) {
	const { noteDetail, listHook, id, writeForm } = useNoteContext();
	const { isPC } = useUserAgent();
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		control,
		reset,
		getValues,
		setValue,
	} = writeForm;
	const [searchParams, setSearchParams] = useSearchParams();
	const tagId = parseInt(searchParams.get('tagId') as string, 10);
	const noteUpdate = TrpcBase.note.update.useMutation();
	const noteCreate = TrpcBase.note.insert.useMutation();

	const doInitStatus = useCallback(() => {
		const tagParams = { tagIds: [tagId] };
		if (id) {
			reset({ ...tagParams, ...noteDetail.data });
			return;
		}

		reset({ ...tagParams, ...initEntity });
	}, [noteDetail, id, reset, tagId]);

	const onSubmit = useCallback(
		// eslint-disable-next-line complexity
		async (entity: PayloadUpdateNoteModel) => {
			const inputUuid = searchParams.get(noteIdQueryConstantName);
			const title =
				(entity?.content?.split('\n')?.[0] ?? '').trim().slice(0, 37) ??
				'non title';

			if ((inputUuid === '' || isNil(inputUuid)) && entity?.content) {
				const insertResult = await noteCreate.mutateAsync({
					title: title,
					content: entity.content,
					type: entity.type,
				});

				await listHook.refetch();
				doInitStatus();
				if (insertResult.code === 0) {
					message.success('创建成功');
				}

				return;
			}

			const params = {
				title: title,
				content: entity.content,
				version: entity.version ?? 0,
				// tagIds: entity.tagIds,
				uuid: inputUuid,
			};

			noteUpdate.mutate(params, {
				onSuccess: async () => {
					const newNoteDetailHook = await noteDetail.refetch();
					await listHook.refetch();

					reset({ ...newNoteDetailHook.data });

					message.success('更新成功');
				},
				onError: (error: any) => {
					whenCatchError(error);
				},
			});
		},
		[doInitStatus, id, isPC, listHook, noteDetail],
	);

	return {
		doInitStatus,
		onSubmit,
		writeForm,
		id,
		isLoading: noteUpdate.isLoading,
	};
}
