import { useUpdateGlobalUserAgent, useUserAgent } from '@/utils/userAgent';
import List from '../../../modules/note/pc/List/List';
import type { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
const ModeExec = ({ children }: { children: ReactNode }) => {
	useUpdateGlobalUserAgent()
	const [searchParams, setSearchParams] = useSearchParams();
	const { isMobile } = useUserAgent();

	return (
		<div className="flex flex-col md:flex-row">

			<List
				height={800}
				onItemClick={(item) => {
					setSearchParams({
						uuid: item.uuid,
					});
				}}
			/>

			{children}
		</div>
	);
};

export default ModeExec;
